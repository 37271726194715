<template>
  <div
    id="app"
    class="wrapper"
  >
    <div
      v-if="currentDestination"
      class="intro intro--divided"
    >
      <error-popup
        title="Oops !"
        :message="errorMessage"
        button-text="Fermer"
        :open="errorPopupOpen"
        @close="currentError = ERROR_NONE"
      />
      <intro-content
        :title="$t('views.register.title')"
        :srcset="secondaryLogoSrcSet"
        :logo="secondaryLogoSrc"
      />
      <aside class="intro__aside">
        <header class="intro__aside__header">
          <a
            href="#"
            class="btn-back"
            @click.prevent="$router.back()"
          >
            <img
              src="../assets/images/icons/ico-arrow-left.svg"
              width="8"
              height="13"
              alt="back button"
            >
            {{ $t(`views.register.texts.${$route.name}`) }}

          </a>
        </header><!-- /.intro__aside__header -->
        <form
          action="/"
          method="post"
          class="form form--light"
        >
          <div class="form__row">
            <text-field
              v-model="state.name"
              field-id="name"
              :field-label="$t('views.register.fields.name')"
              field-type="text"
            />
          </div>

          <div class="form__row">
            <text-field
              v-model="state.email"
              field-id="email"
              :field-label="$t('views.register.fields.email')"
              field-type="email"
            />
          </div>

          <div class="form__row">
            {{ $t('views.register.emailPassword') }}
          </div>
          <div
            v-if="currentDestination.hasOptin === true"
            class="form__row"
          >
            <input
              id="optin"
              v-model="v$.optin.$model"
              type="checkbox"
              fieldID="optin"
              :label="currentDestination.optinText"
              fieldType="optin"
            >
            <label for="optin"> {{ currentDestination.optinText }}</label>
          </div>
          <div
            v-if="currentDestination.hasSecondaryOptin === true"
            class="form__row"
          >
            <input
              id="secondaryOptin"
              v-model="v$.secondaryOptin.$model"
              type="checkbox"
              fieldID="secondaryOptin"
              :label="$t('views.register.fields.email')"
              fieldType="optin"
            >
            <label for="secondaryOptin"> {{ currentDestination.secondaryOptinText }} </label>
          </div>

          <footer class="form__footer">
            <p
              class="form__agreements"
              v-html="$t('views.register.texts.tos')"
            />

            <div class="form__actions">
              <button
                type="submit"
                class="btn btn--block btn--red"
                @click.prevent="submitForm"
              >
                {{ $t('views.register.buttons.register') }}
              </button>
              <span class="form__actions__separator">{{ $t('views.register.texts.account_exists') }}</span>
              <button
                type="submit"
                class="btn btn--block btn--light"
                @click.prevent="redirectToLogin"
              >
                {{ $t('views.register.buttons.login') }}
              </button>
            </div>
            <p
              class="form__entry"
              v-html="$t('views.register.texts.gdpr')"
            />
          </footer>
        </form>
      </aside>
    </div>
  </div>
</template>

<script setup>
import {
  required,
  minLength,
  maxLength,
  email,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import has from 'lodash.has';
import IntroContent from '../components/IntroContent.vue';
import TextField from '../components/FormTextField.vue';
import ErrorPopup from '../components/ErrorPopup.vue';
import GameAPI from '../api/games';
import { reactive, ref, inject, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useCompanyLogo } from '../composables/CompanyLogo';
import { useConfigStore } from '../pinia/configuration';
import { useDestinationStore } from '../pinia/destination';
import { useGameStore } from '../pinia/game';

const ERROR_NO_GAME = 'noGame';
const ERROR_FORM = 'form';
const ERROR_500 = '500';
const ERROR_OTHER = 'other';
const ERROR_NONE = '';
const appConfig = useConfigStore().appConfig;
const router = useRouter();
const destinationStore = useDestinationStore();
const gameStore = useGameStore();

const { secondaryLogoSrc, secondaryLogoSrcSet } = useCompanyLogo();
const { t } = useI18n();


const rules = {
  name: {required, minLength: minLength(2), maxLength: maxLength(50)},
  email: {required, email},
}

const state = reactive({
  name: '',
  email: '',
  optin: false,
  secondaryOptin: false,
});

const v$ = useVuelidate(rules, state);

const currentError = ref(ERROR_NONE);
const errorPopupOpen = computed(() => currentError.value !== ERROR_NONE);
const errorMessage = computed(() => {
  switch (currentError.value) {
    case ERROR_FORM:
      return t('views.register.validations.form');
    case ERROR_500:
      return t('views.register.validations.emailExists');
    case ERROR_OTHER:
      return t('views.register.validations.serverError');
    case ERROR_NO_GAME:
      return t('views.register.noGame');
    default:
      return '';
  }
})

const empty = ref(false);

const currentDestination = computed(() => destinationStore.currentDestination);

const redirectToLogin = async () => {
  await router.push({ name: 'Login' });
};

const submitForm = async () => {
  currentError.value = '';
  const isFormValid = await v$.value.$validate();
  if (!isFormValid) {
    currentError.value = ERROR_FORM;
    v$.value.$errors.forEach((error) => {
      console.log(error);
    });
  } else {
    await doRegister();

  }
}
const doRegister = async() => {
 currentError.value = ERROR_NONE;
  const gamesList = await GameAPI.getGames(appConfig.gameApiBaseUrl, currentDestination.value.uuid, 'started');
  if (!Array.isArray(gamesList) || gamesList.length !== 1) {
    currentError.value = ERROR_NO_GAME;
    return;
    }
    const teamToCreate = {
      name: state.name,
      email: state.email,
      optin: state.optin,
      secondaryOptin: state.secondaryOptin,
      partyId: gamesList[0].id,
      destinationId: currentDestination.value.uuid,
      color: '#FF0000',

    };
    try {
    const team = await gameStore.addTeam(teamToCreate);
    router.push({ name: 'home'});
  } catch (error) {
    console.log('exception', error);
    Sentry.captureException(error);
        if (has(error, 'response') && error.response.status === 409) {
          currentError.value = ERROR_500;
        } else {
          currentError.value = ERROR_OTHER;

        }
  }
}

/* eslint-disable */
</script>


<style scoped>
.errors {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  background-color: rgba(218, 0, 75, 0.6);
}
</style>

